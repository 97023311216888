@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
.ace-c-sidebar-expansion-button {
    position: absolute;
    top: calc(50% - 44px);
    left: -24px;
    height: 88px;
    cursor: pointer;
    background-color: #fff;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    padding: 32px 8px;
    z-index: 1;

    &--shrinked {
        left: -32px;
        z-index: 1;
    }
}
