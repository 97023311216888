@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './highlight-rectangle-config';

.ace-c-highlight-rectangle {
    display: inline-flex;
    padding: map-get($highlight-rectangle-config, highlight-rectangle-padding);
    color: map-get($highlight-rectangle-config, highlight-rectangle-text-color);
    background: map-get($highlight-rectangle-config, highlight-rectangle-background);

    &--pending {
        color: map-get($highlight-rectangle-config, highlight-rectangle-text-color-pending);
        background: map-get($highlight-rectangle-config, highlight-rectangle-background-pending);
    }

    &--positive {
        color: map-get($highlight-rectangle-config, highlight-rectangle-text-color-positive);
        background: map-get($highlight-rectangle-config, highlight-rectangle-background-positive);
    }

    &--negative {
        color: map-get($highlight-rectangle-config, highlight-rectangle-text-color-negative);
        background: map-get($highlight-rectangle-config, highlight-rectangle-background-negative);
    }

    &--information {
        color: map-get($highlight-rectangle-config, highlight-rectangle-text-color-information);
        background: map-get($highlight-rectangle-config, highlight-rectangle-background-information);
    }
}
