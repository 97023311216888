@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
.ace-c-filter-pois-widget {
    min-width: 240px;
    background-color: #fff;
    box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.08);
    padding: 24px 0;
}

.ace-c-filter-pois-widget__header {
    font-style: normal;
    font-weight: bold;
    line-height: 148%;
    letter-spacing: 0.04em;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #565454;
    padding: 0 24px;
    margin-bottom: 16px;
}

.ace-c-filter-pois-widget__content {
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    letter-spacing: 0.01em;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #565454;
}

.ace-c-filter-pois-widget__filtering-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e1e1e2;
    cursor: pointer;
}

.ace-c-filter-pois-widget__button-container {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 0 24px;
}

.ace-c-filter-pois-widget__button {
    background-color: #ec901d;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: #fff;
    padding: 16px 32px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
}

.ace-c-filter-pois-widget__button-label {
    background-color: transparent;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: #565454;
    padding: 16px 32px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
}
