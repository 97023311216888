@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './modal-config';

.ace-c-modal {
    display: inline-flex;
    flex-direction: column;
    min-width: map-get($modal-config, modal-content-min-width-small);
    max-width: map-get($modal-config, modal-content-max-width);
    background-color: map-get($modal-config, modal-content-background);
    padding: map-get($modal-config, modal-content-padding);
    box-shadow: map-get($modal-config, modal-content-shadow);
    max-height: 100%;

    @include media('>=large') {
        min-width: map-get($modal-config, modal-content-min-width);
    }

    &--full-scrollable {
        overflow-y: auto;
    }

    &--no-padding {
        padding: 0;
    }
}

.ace-c-modal__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    &--has-background-color {
        padding: map-get($modal-config, modal-content-padding);
        background-color: map-get($modal-config, modal-header-background-color);
    }
}

.ace-c-modal__header-title {
    @include typography($typography-styles, map-get($modal-config, modal-title-typography));

    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
}

.ace-c-modal__header-action {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    justify-items: flex-end;
}

.ace-c-modal__content {
    display: flex;
    flex-flow: row wrap;

    &--scrollable {
        overflow-y: auto;
    }

    &--full-bleed {
        margin: 0 map-get($modal-config, modal-content-padding) * -1 map-get($modal-config, modal-content-padding) * -1;
    }

    &--has-padding {
        padding: map-get($modal-config, modal-content-padding);
    }
}

.ace-c-modal__backdrop {
    width: 100%;
    height: 100%;
    background-color: map-get($modal-config, modal-overlay-background-transparent);
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: map-get($modal-config, modal-overlay-z-index);
    padding: map-get($modal-config, modal-overlay-padding) 0;

    @include media('>=large') {
        padding:
            map-get($modal-config, modal-config-overlay-padding)
            map-get($modal-config, modal-overlay-padding-large);
    }
}

.ace-c-modal__backdrop--dark {
    background-color: map-get($modal-config, modal-overlay-background-dark);
}
