@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';

.ace-c-yo-dawg-down {
    display: inline-block;
    padding: map-get($sizes, ace-4);
    border: 1px solid map-get($color-palette, ace-red-800);
    background-color: map-get($color-palette, ace-red-300);
    cursor: pointer;
    user-select: none;
}

.ace-c-yo-dawg-down__drop-down {
    width: 384px;
    height: 256px;
    padding: 16px;
}
