@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './tooltip-config';

.ace-c-tooltip {
    position: relative;
}

.ace-c-tooltip__message {
    display: none;
    position: absolute;
    width: max-content;
    background-color: map-get($tooltip-config, tooltip-background);
    color: map-get($tooltip-config, tooltip-color);
    border-radius: map-get($tooltip-config, tooltip-border-radius);
    z-index: map-get($tooltip-config, tooltip-z-index);

    &--top-left {
        bottom: 100%;
        right: 10%;
        margin-bottom: map-get($tooltip-config, tooltip-message-margin);
    }

    &--top-right {
        bottom: 100%;
        left: 10%;
        margin-bottom: map-get($tooltip-config, tooltip-message-margin);
    }

    &--bottom-left {
        top: 100%;
        right: 10%;
        margin-top: map-get($tooltip-config, tooltip-message-margin);
    }

    &--bottom-right {
        top: 100%;
        left: 10%;
        margin-top: map-get($tooltip-config, tooltip-message-margin);
    }
}

.ace-c-tooltip__hover-element {
    &:hover + .ace-c-tooltip__message {
        display: inline-block;
    }
}
