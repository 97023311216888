@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './date-picker-config';

%table-body {
    display: grid;
    width: 100%;
}

%years-months-table-cell {
    @include typography($typography-styles, map-get($date-picker-config, date-picker-text-typography));

    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: map-get($date-picker-config, date-picker-cell-text-color);
    background: map-get($date-picker-config, date-picker-cell-background-color);
    cursor: pointer;
}

.ace-c-date-picker {
    width: map-get($date-picker-config, date-picker-width-small);
    box-shadow: map-get($date-picker-config, date-picker-shadow);
    padding: map-get($date-picker-config, date-picker-padding-small);
    border-radius: map-get($date-picker-config, date-picker-border-radius);

    @include media('>=small') {
        width: map-get($date-picker-config, date-picker-width);
        padding: map-get($date-picker-config, date-picker-padding);
    }
}

.ace-c-date-picker__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 5px;
    height: 40px;
    width: 100%;
}

.ace-c-date-picker__header-arrow-wrapper {
    display: flex;
}

.ace-c-date-picker__header-arrow {
    width: map-get($date-picker-config, date-picker-header-arrow-width);
    height: map-get($date-picker-config, date-picker-header-arrow-height);
    background-repeat: no-repeat;
    cursor: pointer;

    &--left {
        // stylelint-disable-next-line max-line-length
        background-image: url('../assets/icons/arrow-left.svg?fill=#{map-get($date-picker-config, date-picker-text-color)}');
        margin-right: 48px;
    }

    &--right {
        // stylelint-disable-next-line max-line-length
        background-image: url('../assets/icons/arrow-right.svg?fill=#{map-get($date-picker-config, date-picker-text-color)}');
    }
}

.ace-c-date-picker__table-header {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
}

.ace-c-date-picker__table-header-cell {
    @include typography($typography-styles, map-get($date-picker-config, date-picker-heading-typography));

    height: map-get($date-picker-config, date-picker-header-cell-height-small);
    width: map-get($date-picker-config, date-picker-header-cell-width-small);
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($date-picker-config, date-picker-text-color);

    @include media('>=small') {
        height: map-get($date-picker-config, date-picker-header-cell-height);
        width: map-get($date-picker-config, date-picker-header-cell-width);
    }
}

.ace-c-date-picker__date-table-body {
    @extend %table-body;

    grid-template-columns: repeat(7, 1fr);
}

.ace-c-date-picker__date-table-cell {
    @include typography($typography-styles, map-get($date-picker-config, date-picker-text-typography));

    height: map-get($date-picker-config, date-picker-cell-height-small);
    width: map-get($date-picker-config, date-picker-cell-width-small);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: map-get($date-picker-config, date-picker-cell-border);
    background: map-get($date-picker-config, date-picker-cell-background-color);
    color: map-get($date-picker-config, date-picker-cell-text-color);
    cursor: pointer;

    @include media('>=small') {
        height: map-get($date-picker-config, date-picker-cell-height);
        width: map-get($date-picker-config, date-picker-cell-width);
    }

    &:hover:not(#{&}--is-selected):not(#{&}--is-disabled) {
        border: map-get($date-picker-config, date-picker-cell-border-hover);
        background: map-get($date-picker-config, date-picker-cell-background-color-hover);
        color: map-get($date-picker-config, date-picker-cell-text-color-hover);
    }

    &--is-current-date {
        border: map-get($date-picker-config, date-picker-cell-border-current);
        background: map-get($date-picker-config, date-picker-cell-background-color-current);
        color: map-get($date-picker-config, date-picker-cell-text-color-current);
    }

    &--is-disabled {
        cursor: default;
        border: none;
        background: none;
        color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
        -webkit-text-fill-color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
    }

    &--is-selected {
        // stylelint-disable-next-line max-line-length
        @include typography($typography-styles, map-get($date-picker-config, date-picker-cell-text-typography-selected));

        border: map-get($date-picker-config, date-picker-cell-border-selected);
        background: map-get($date-picker-config, date-picker-cell-background-color-selected);
        color: map-get($date-picker-config, date-picker-cell-text-color-selected);
    }
}

.ace-c-date-picker__input-time > div > input {
    width: 100%;
}

.ace-c-date-picker__years-table-body {
    @extend %table-body;

    grid-template-columns: repeat(4, 1fr);
}

.ace-c-date-picker__years-table-cell {
    @extend %years-months-table-cell;

    &:hover:not(#{&}--is-selected):not(#{&}--is-disabled) {
        background: map-get($date-picker-config, date-picker-cell-background-color-hover);
        color: map-get($date-picker-config, date-picker-cell-text-color-hover);
    }

    &--is-current {
        background: map-get($date-picker-config, date-picker-cell-background-color-current);
        color: map-get($date-picker-config, date-picker-cell-text-color-current);
    }

    &--is-disabled {
        cursor: default;
        background: none;
        color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
        -webkit-text-fill-color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
    }

    &--is-selected {
        // stylelint-disable-next-line max-line-length
        @include typography($typography-styles, map-get($date-picker-config, date-picker-cell-text-typography-selected));

        background: map-get($date-picker-config, date-picker-cell-background-color-selected);
        color: map-get($date-picker-config, date-picker-cell-text-color-selected);
    }
}

.ace-c-date-picker__months-table-body {
    @extend %table-body;

    grid-template-columns: repeat(4, 1fr);
}

.ace-c-date-picker__months-table-cell {
    @extend %years-months-table-cell;

    &:hover:not(#{&}--is-selected):not(#{&}--is-disabled) {
        background: map-get($date-picker-config, date-picker-cell-background-color-hover);
        color: map-get($date-picker-config, date-picker-cell-text-color-hover);
    }

    &--is-current {
        background: map-get($date-picker-config, date-picker-cell-background-color-current);
        color: map-get($date-picker-config, date-picker-cell-text-color-current);
    }

    &--is-disabled {
        cursor: default;
        background: none;
        color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
        -webkit-text-fill-color: map-get($date-picker-config, date-picker-cell-text-color-disabled);
    }

    &--is-selected {
        // stylelint-disable-next-line max-line-length
        @include typography($typography-styles, map-get($date-picker-config, date-picker-cell-text-typography-selected));

        background: map-get($date-picker-config, date-picker-cell-background-color-selected);
        color: map-get($date-picker-config, date-picker-cell-text-color-selected);
    }
}
