@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './checkbox-config';

@include state-styles.configure('ace-c-checkbox', $checkbox-config);

.ace-c-checkbox {
    @include typography($typography-styles, map-get($checkbox-config, checkbox-typography));

    position: relative;
    display: inline-flex;
    align-items: center;
    color: map-get($checkbox-config, checkbox-label-color);
    cursor: pointer;
    user-select: none;

    &--is-disabled {
        cursor: default;
    }

    @include state-styles.block() using($get-design-token) {
        @include typography($typography-styles, meta.call($get-design-token, checkbox-typography));

        color: meta.call($get-design-token, checkbox-label-color);
    }
}

.ace-c-checkbox__native-input {
    opacity: 0;
    position: absolute;
    left: -999px;
}

.ace-c-checkbox__input {
    position: relative;
    margin-right: map-get($checkbox-config, checkbox-label-spacing);
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: map-get($checkbox-config, checkbox-border-radius);
    border: map-get($checkbox-config, checkbox-border);
    background-color: map-get($checkbox-config, checkbox-background);
    background-repeat: no-repeat;
    background-position: center center;
    pointer-events: none;

    .ace-c-checkbox--no-chrome & {
        display: none;
    }

    &:focus {
        border: map-get($checkbox-config, checkbox-border-focus);
        box-shadow: map-get($checkbox-config, checkbox-shadow-focus);
        outline: 0;
    }

    @include state-styles.element() using($get-design-token) {
        border: meta.call($get-design-token, checkbox-border);
        background-color: meta.call($get-design-token, checkbox-background);
    }

    @include state-styles.element(
        $for-states: (active),
    ) using($get-design-token) {
        box-shadow: meta.call($get-design-token, checkbox-shadow);
    }

    @include state-styles.element(
        $for-states: (selected, selected-disabled, hover-selected),
    ) using($get-design-token) {
        background-image: url('../assets/icons/checkmark.svg?fill=#{meta.call($get-design-token, checkbox-checkmark)}');
    }

    @include state-styles.element(
        $for-states: (selected, selected-disabled, hover-selected),
        $block-selector-suffix: '.ace-c-checkbox--is-indeterminate',
    ) using($get-design-token) {
        background-image: url('../assets/icons/checkmark-dash.svg?fill=#{meta.call($get-design-token, checkbox-checkmark)}');
    }
}
