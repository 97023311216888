@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
.ace-c-contract-partner-operation-area-tab__left-sidebar {
    display: block;
    margin-left: -20px;
    z-index: 1;

    &--shrinked {
        display: none;
    }
}
