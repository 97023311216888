@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './input-counter-config';

@include state-styles.configure('ace-c-input-counter', $input-counter-config);

.ace-c-input-counter {
    min-width: 280px;
    display: inline-flex;
    justify-content: map-get($input-counter-config, input-counter-justify-content);
    align-items: center;
    margin: 0;
    padding: 0;
}

.ace-c-input-counter--list-element {
    padding: map-get($input-counter-config, input-counter-list-element) 0;
}

.ace-c-input-counter__label {
    @include typography($input-counter-config, input-counter-typography);

    color: map-get($input-counter-config, input-counter-label-color);
    margin-right: map-get($input-counter-config, input-counter-label-margin);
}

.ace-c-input-counter__controls {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ace-c-input-counter__plus-button,
.ace-c-input-counter__minus-button {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid map-get($input-counter-config, input-counter-button-border-color);
    background-color: map-get($input-counter-config, input-counter-background-color);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include state-styles.block(
        $not-for-states: (selected, selected-disabled, selected-hover, active),
    ) using($get-design-token) {
        border-color: meta.call($get-design-token, input-counter-button-border-color);

        > svg {
            fill: meta.call($get-design-token, input-counter-icon-color);
        }
    }
}

.ace-c-input-counter__minus-icon {
    width: 14px;
    height: 2px;
    fill: map-get($input-counter-config, input-counter-icon-color);
}

.ace-c-input-counter__plus-icon {
    width: 14px;
    height: 14px;
    fill: map-get($input-counter-config, input-counter-icon-color);
}

.ace-c-input-counter__button__input {
    @include typography($input-counter-config, input-counter-typography);

    border: none;
    background-color: inherit;
    text-align: center;
    margin: 0 map-get($input-counter-config, input-counter-input-margin);
}
