@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './tab-config';

@include state-styles.configure('ace-c-tab', $tab-config);

.ace-c-tab {
    @include typography($typography-styles, map-get($tab-config, tab-typography));

    display: inline-block;
    height: map-get($tab-config, tab-height);
    background: map-get($tab-config, tab-background);
    color: map-get($tab-config, tab-text-color);
    padding: map-get($tab-config, tab-padding);
    cursor: pointer;
    text-transform: uppercase;

    &--is-disabled {
        cursor: default;
    }

    &--no-wrap {
        white-space: nowrap;
        z-index: map-get($tab-config, tab-z-index);
    }

    &--secondary-background {
        background: map-get($tab-config, tab-secondary-background);
    }

    @include state-styles.block() using($get-design-token) {
        @include typography($typography-styles, meta.call($get-design-token, tab-typography));

        color: meta.call($get-design-token, tab-text-color);
        background: meta.call($get-design-token, tab-background);
        border-bottom: meta.call($get-design-token, tab-border);

        &.--secondary-background {
            background: meta.call($get-design-token, tab-secondary-background);
        }
    }
}

.ace-c-tab__navigation-arrow {
    width: map-get($tab-config, tab-navigation-icon-size);
    height: map-get($tab-config, tab-navigation-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: map-get($tab-config, tab-navigation-icon-radius);
    background: map-get($tab-config, tab-navigation-icon-background);
    box-shadow: map-get($tab-config, tab-navigation-icon-shadow);
    cursor: pointer;

    &--is-disabled {
        cursor: default;
    }
}

.ace-c-tab__navigation-arrow-icon {
    .ace-c-tab__navigation-arrow--is-disabled & {
        > svg {
            fill: map-get($tab-config, tab-navigation-icon-disabled);
        }
    }
}
