@use 'sass:list';
@use 'sass:map';
@use './content-layout-config' as *;

@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';

$content-columns: map-get($content-layout-config, content-columns);
$content-column-gap: map-get($content-layout-config, content-column-gap);
$content-column-breakpoints: map.keys($breakpoints);

// Creates modifier span classes for given $breakpoint
@mixin span-modifier-classes($breakpoint: '') {
    $breakpoint-key: '';

    @if $breakpoint != '' {
        $breakpoint-key: #{$breakpoint}-;
    }

    @for $i from 1 through $content-columns {
        // .ace-c-content-item--[breakpoint-]span-{1,i}
        .ace-c-content-item--#{$breakpoint-key}span-#{$i} {
            $layout-gaps: $content-columns * $content-column-gap;
            $span-gaps: ($i - 1) * $content-column-gap;

            flex-grow: 0;
            width: calc((100% - #{$layout-gaps}) * (#{$i} / #{$content-columns}) + #{$span-gaps});
        }
    }
}

.ace-c-content-item {
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    margin: 0 map-get($content-layout-config, content-column-margin);

    &--sidebar {
        margin: 0 0 -10px -44px;
    }
}

// non-responsive span modifiers
@include span-modifier-classes();

@each $breakpoint in $content-column-breakpoints {
    $breakpoint-index: list.index($content-column-breakpoints, $breakpoint);

    @if $breakpoint-index > 1 {
        $media-breakpoint: list.nth($content-column-breakpoints, $breakpoint-index - 1);

        @include media('>=#{$media-breakpoint}') {
            // responsive span modifiers
            @include span-modifier-classes($breakpoint);
        }
    }
}
