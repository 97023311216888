@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './linked-list-item-config';

.ace-c-linked-list-item {
    position: relative;
    list-style: none;
    word-break: break-word;
    padding-left: map-get($linked-list-item-config, linked-list-item-padding-left);
    margin-top: -1px;

    &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        border: 2px solid map-get($linked-list-item-config, linked-list-item-bullet-border-color);
        border-radius: 50%;
        left: 0;
        top: 8px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        border-left: 2px solid map-get($linked-list-item-config, linked-list-item-divider);
        left: 7px;
        top: 23px;
    }

    &:last-of-type::after {
        display: none;
    }

    i > svg {
        display: none;
    }
}

.ace-c-linked-list-item--hoverable div:hover {
    background-color: map-get($linked-list-item-config, linked-list-item-on-hover);
    cursor: pointer;

    i > svg {
        display: block;
    }
}
