@import '/home/jenkins/agent/workspace/DEV/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './file-drop-zone-config';

.ace-c-file-drop-zone {
    border: map-get($file-drop-zone-config, file-drop-zone-border);

    &--is-dragging-active {
        border: map-get($file-drop-zone-config, file-drop-zone-border-active);
    }
}
